<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Category List</h4>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New
        </b-button>
      </div>
      <div class="mb-2">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Search by keyword"
              label-for="v-search-keyword"
            >
              <b-form-input
                id="h-search-keyword"
                v-model="filter"
                type="text"
                placeholder="search here..."
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive="sm"
        :items="categories"
        :fields="fields"
        :per-page="perPage"
        sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        :current-page="currentPage"
        @row-clicked="showDetails"
      />
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        class="my-0 pagination-success"
      />
    </b-card>

    <b-modal
      id="app-modal"
      :title="is_update ? 'Edit' : 'Add New'"
      hide-footer
      @hide="hide"
    >
      <b-card-text>
        <validation-observer ref="categoryForm">
          <b-form @submit.prevent="is_update ? updateCategory() : createCategory()">
            <b-row>
              <!-- first name -->
              <b-col cols="12">
                <b-form-group
                  label="Name"
                  label-for="v-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="v-name"
                      v-model="category.name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="NAICS"
                  label-for="v-naics"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="naics"
                    rules="required"
                  >
                    <b-form-input
                      id="v-naics"
                      v-model="category.naics"
                      placeholder="naics"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Require License"
                  label-for="v-naics"
                >

                    <b-form-checkbox
                      v-model="category.state_license_required"
                      :checked="category.state_license_required === true ? true: false"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    />
                </b-form-group>
              </b-col>

            </b-row>
            <div class="mt-4 d-flex align-items-center justify-content-between">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>

              <b-button
                v-if="is_update"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                @click="deleteData"
              >
                Delete
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                type="submit"
                :disabled="is_edited === false && is_update ? true : false"
              >
                Save
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BPagination, BFormCheckbox, BTable, BRow, BCol, BFormGroup, BFormInput, BForm, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BPagination,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      required,
      filter: null,
      sortBy: 'id',
      sortDesc: false,
      filterOn: [],
      fields: [{ key: 'id', label: '#ID' }, { key: 'name', label: 'name', sortable: true }, 'naics'],
      perPage: 10,
      pageOptions: [3, 5, 10],
      currentPage: 1,
      category: { name: '', naics: '', state_license_required: true },
      is_edited: false,
    }
  },
  computed: {
    categories() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.$store.state.category.categories.reverse()
    },
    totalRows() {
      return this.categories.length
    },
  },
  watch: {
    category: {
      handler(oldValue, newValue) {
        if (newValue && this.is_update) {
          this.is_edited = true
        }
      },
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
    getCategory() {
      return this.$store.dispatch('category/getCategories')
    },
    createCategory() {
      this.$refs.categoryForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('category/createCategory', this.category)
          this.hide()
        }
      })
    },
    updateCategory() {
      this.$refs.categoryForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('category/updateCategory', this.category)
          this.is_edited = false
          this.hide()
        }
      })
    },
    deleteData() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('category/deleteCategory', this.category.id)
          this.is_edited = false
          this.hide()
        }
      })
    },
    emptyData() {
      const dom = this
      setTimeout(() => {
        dom.category = { name: '', naics: '' }
      }, 500)
    },
    showModal() {
      this.is_update = false
      this.$bvModal.show('app-modal')
    },
    hide(e) {
      if (this.is_edited === true) {
        e.preventDefault()
        this.$swal({
          title: 'Do you want to save changes',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save Changes',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.updateCategory()
            this.is_edited = false
          } else if (result.dismiss === 'cancel') {
            this.is_update = false
            this.is_edited = false
            this.$bvModal.hide('app-modal')
            this.getCategory()
            this.emptyData()
          }
        })
      } else {
        this.is_update = false
        this.$bvModal.hide('app-modal')
        this.is_edited = false
        this.emptyData()
      }
    },
    showDetails(item) {
      this.is_update = true
      this.category = item
      this.$bvModal.show('app-modal')
      const dom = this
      setTimeout(() => {
        dom.is_edited = false
      }, 1000)
    },
  },
}
</script>

  <style>

  </style>
